<template>
  <div class="fixed z-modal container top-0 inset-x-0 pointer-events-none">
    <div class="search bg-white border-t p-16 pointer-events-auto overflow-y-auto" data-testid="searchPanel">
      <div class="flex">
        <i
          class="material-icons cursor-pointer ml-auto"
          @click="closeSearchpanel"
          data-testid="closeSearchPanel"
        >
          close
        </i>
      </div>
      <div class="relative flex mt-4 mb-16">
        <input
          ref="search"
          id="search"
          v-model="search"
          @input="makeSearch"
          class="w-full p-5 pr-16 text-md bg-grey-5 focus:outline-none"
          :placeholder="$t('I am looking for...')"
          type="text"
          autofocus="true"
        >
        <i
          class="material-icons absolute right-0 inset-y-0 px-5 flex items-center"
        >
          search
        </i>
      </div>
      <div v-if="resultsCount">
        <h3 class="mb-4 font-normal text-base">
          {{ $t('Results') }}: ({{ resultsCount }})
        </h3>
        <template v-if="products.length">
          <search-result
            v-for="item in products"
            :key="item.id"
            :item="productResult(item)"
            @click.native="closeSearchpanel"
          />
        </template>
        <template v-if="categories.length">
          <search-result
            v-for="item in categories"
            :key="item.category_id"
            :item="categoryResult(item)"
            @click.native="closeSearchpanel"
          />
        </template>
        <template v-if="pages.length">
          <search-result
            v-for="item in pages"
            :key="item.id"
            :item="pageResult(item)"
            @click.native="closeSearchpanel"
          />
        </template>
      </div>
      <div v-if="emptyResults">
        {{ $t('No results') }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { Logger } from '@vue-storefront/core/lib/logger'
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel'
import SearchResult from 'theme/components/core/blocks/SearchPanel/SearchResult'
import VueOfflineMixin from 'vue-offline/mixin'
import { prepareCategorySearchQuery } from 'theme/queries/searchPanel'

export default {
  components: {
    SearchResult
  },
  mixins: [SearchPanel, VueOfflineMixin],
  data () {
    return {
      categories: [],
      pages: [],
      size: 20
    }
  },
  computed: {
    resultsCount () {
      return this.products.length + this.categories.length + this.pages.length
    }
  },
  methods: {
    async makeSearch () {
      if (this.search !== '' && this.search !== undefined) {
        let productQuery = this.buildSearchQuery(this.search)

        this.emptyResults = true

        try {
          let prodResp = await this.$store.dispatch('product/list', { query: productQuery, start: 0, size: this.size / 2, updateState: false })

          this.products = prodResp.items
          this.emptyResults = this.emptyResults && prodResp.items.length < 1
        } catch (err) {
          Logger.error(err, 'components-search')()
        }

        let categoryQuery = prepareCategorySearchQuery(this.search)

        try {
          let catResp = await quickSearchByQuery({ entityType: 'category', query: categoryQuery, sort: 'position:asc', start: 0, size: this.size / 2, includeFields: this.$store.state.config.entities.optimize ? this.$store.state.config.entities.category.includeFields : null, excludeFields: this.$store.state.config.entities.optimize ? this.$store.state.config.entities.category.excludeFields : null })

          this.categories = catResp.items
          this.emptyResults = this.emptyResults && catResp.items.length < 1
        } catch (err) {
          Logger.error(err, 'components-search')()
        }
      } else {
        this.products = []
        this.categories = []
        this.pages = []
        this.emptyResults = 0
      }
    },
    productResult (product) {
      return {
        type: i18n.t('Product'),
        title: product.name,
        url: {
          name: product.type_id + '-product',
          params: {
            parentSku: product.parentSku ? product.parentSku : product.sku,
            slug: product.slug,
            childSku: product.sku
          }
        }
      }
    },
    categoryResult (category) {
      return {
        type: i18n.t('Category'),
        title: category.name,
        url: ['/', category.url_path].join('')
      }
    },
    pageResult (page) {
      return {
        type: i18n.t('Page'),
        title: page.name,
        url: {
          name: 'cms-data',
          params: {
            slug: page.slug
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  height: calc(100vh - 120px);
  margin-top: 120px;
  transition: height, margin-top .3s ease;
}

.header-compact .search {
  height: calc(100vh - 76px);
  margin-top: 76px;
}
</style>
